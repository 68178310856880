<template>
  <div>
    <b-alert variant="danger" :show="error.length > 0">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrio un error al obtener los Samplings {{ error }}
      </div>
    </b-alert>

    <div style="text-align: center; margin: 45vh 0" v-if="loading">
      <b-spinner label="Loading..." />
    </div>

    <b-row v-else>
      <b-col cols="12" md="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Samplings</b-card-title>
            <b-button
              @click="onCreateClick"
              variant="primary"
              :disabled="loading"
            >
              Crear
            </b-button>
          </b-card-header>

          <b-card-body>
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Buscar</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Buscar"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
              :rows="rows"
              :columns="columns"
              @on-row-click="onSamplingClick"
              :sort-options="{
                enabled: false,
              }"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Fecha Inicio-->
                <span v-if="props.column.field === 'fechaInicio'">
                  <p class="mb-0">{{ formattedDate(props.row.fechaInicio) }}</p>
                  <p class="mb-0">{{ formattedHour(props.row.fechaInicio) }}</p>
                </span>
                <!-- Column: Fecha Fin-->
                <span v-else-if="props.column.field === 'fechaFin'">
                  <p class="mb-0">{{ formattedDate(props.row.fechaFin) }}</p>
                  <p class="mb-0">{{ formattedHour(props.row.fechaFin) }}</p>
                </span>
                <!-- Column: Estado -->
                <span v-else-if="props.column.field === 'activo'">
                  <b-badge :variant="estadoVariant(props.row.activo)">
                    {{ estadoText(props.row.activo) }}
                  </b-badge>
                </span>
                <!-- Column: Tipo -->
                <span v-else-if="props.column.field === 'tipo'">
                  <b-badge :variant="tipoVariant(props.row.tipo)">
                    {{ tipoText(props.row.tipo) }}
                  </b-badge>
                </span>
                <!-- Column: Otras Columnas -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Cantidad de samplings </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '15', '20']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <crear-editar
          v-if="selectedSampling !== null"
          :sampling="selectedSampling"
          :refreshData="getSamplingData"
        />
        <div v-else class="iconEmpty">
          <feather-icon icon="GiftIcon" size="100" />
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modal-error"
      ok-only
      ok-variant="danger"
      ok-title="Accept"
      modal-class="modal-danger"
      centered
      ref="modal-error"
      title="Error"
    >
      <b-card-text>
        {{ error }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BBadge,
  BAvatar,
  BButton,
  BSpinner,
  BCardBody,
  BDropdown,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCardHeader,
  BPagination,
  BDropdownItem,
} from "bootstrap-vue";
import moment from "moment";
import CrearEditar from "./CrearEditar.vue";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAlert,
    BAvatar,
    BButton,
    BSpinner,
    BDropdown,
    BCardBody,
    BCardText,
    BFormGroup,
    BFormInput,
    BCardTitle,
    CrearEditar,
    BCardHeader,
    BFormSelect,
    BPagination,
    VueGoodTable,
    BDropdownItem,
  },
  data() {
    return {
      columns: [
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Inicio",
          field: "fechaInicio",
        },
        {
          label: "Fin",
          field: "fechaFin",
        },
        {
          label: "Tipo",
          field: "tipo",
        },
        {
          label: "Estado",
          field: "activo",
        },
      ],
      rows: [],
      error: "",
      loading: true,
      searchTerm: "",
      pageLength: 10,
      selectedSampling: null,
    };
  },
  computed: {
    tipoText() {
      const status = {
        MONTO: "Monto",
        CLIENTE: "Cliente",
        PRODUCTO: "Producto",
        CATEGORIA: "Categoria",
        PROMOCODE: "Promocode",
      };
      return (s) => status[s];
    },
    tipoVariant() {
      const statusColor = {
        MONTO: "light-info",
        CLIENTE: "light-warning",
        PRODUCTO: "light-success",
        CATEGORIA: "light-secondary",
        PROMOCODE: "light-dark",
      };
      return (s) => statusColor[s];
    },
    estadoText() {
      return (s) => (s ? "Activo" : "Inactivo");
    },
    estadoVariant() {
      return (s) => (s ? "light-success" : "light-secondary");
    },
  },
  methods: {
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(new Date(originalDate)).format("hh:mm a");
    },
    onCreateClick() {
      this.selectedSampling = {
        id: 0,
        activo: false,
        productos: [],
        categorias: [],
      };
    },
    onSamplingClick(params) {
      this.selectedSampling = JSON.parse(JSON.stringify(params.row));
    },
    getSamplingData() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/GetSamplings",
            body: JSON.stringify({
              empresa: 2,
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.rows = res.data.samplings;
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.error = e.message;
            reject(e.message);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
  created() {
    this.getSamplingData();
  },
};
</script>

<style lang="scss" >
table.vgt-table {
  font-size: 13px !important;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
