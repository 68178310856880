var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" " + _vm._s(_vm.sampling.id === 0 ? "Crear Sampling" : "Modificar Sampling"))]), _c('div', [_c('b-form-group', {
    attrs: {
      "label": "Activo",
      "label-for": "vi-activo"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-activo",
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.sampling.activo,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "activo", $$v);
      },
      expression: "sampling.activo"
    }
  })], 1)], 1)], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-radio-group', {
    attrs: {
      "id": "btn-tipo",
      "button-variant": "outline-primary",
      "options": _vm.tipos,
      "buttons": "",
      "size": "sm"
    },
    on: {
      "change": _vm.onChangeType
    },
    model: {
      value: _vm.sampling.tipo,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "tipo", $$v);
      },
      expression: "sampling.tipo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "GiftIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "placeholder": "Nombre del item"
    },
    model: {
      value: _vm.sampling.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "nombre", $$v);
      },
      expression: "sampling.nombre"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Descripcion",
      "label-for": "vi-descripcion"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BookIcon"
    }
  })], 1), _c('b-form-textarea', {
    attrs: {
      "id": "vi-descripcion",
      "placeholder": "Descripcion",
      "rows": "4",
      "no-resize": ""
    },
    model: {
      value: _vm.sampling.descripcion,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "descripcion", $$v);
      },
      expression: "sampling.descripcion"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Producto a entregar",
      "label-for": "vi-producto-sampling"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "nombre",
      "reduce": function reduce(producto) {
        return producto.id;
      },
      "loading": _vm.loadingProductosSampling,
      "options": _vm.productosSampling,
      "clearable": false,
      "filterable": true
    },
    model: {
      value: _vm.sampling.productoSampling,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "productoSampling", $$v);
      },
      expression: "sampling.productoSampling"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Inicio",
      "label-for": "vi-fecha-ini"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  })], 1), _c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "id": "vi-fecha-ini",
      "config": {
        enableTime: true,
        altInput: true,
        altFormat: 'd/m/Y H:i',
        dateFormat: 'Y-m-dTH:i:ss'
      }
    },
    model: {
      value: _vm.sampling.fechaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "fechaInicio", $$v);
      },
      expression: "sampling.fechaInicio"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Fecha Fin",
      "label-for": "vi-fecha-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  })], 1), _c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "id": "vi-fecha-fin",
      "min-date": _vm.sampling.fechaInicio,
      "config": {
        altInput: true,
        altFormat: 'd/m/Y H:i',
        dateFormat: 'Y-m-dTH:i:ss',
        enableTime: true
      }
    },
    model: {
      value: _vm.sampling.fechaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "fechaFin", $$v);
      },
      expression: "sampling.fechaFin"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Monto mínimo de compra",
      "label-for": "vi-monto"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon"
    }
  })], 1), _vm.sampling.tipo !== 'CLIENTE' && _vm.sampling.tipo !== 'PROMOCODE' ? _c('b-form-input', {
    attrs: {
      "id": "vi-monto",
      "type": "number",
      "step": "0.05",
      "placeholder": "Monto mínimo",
      "min": "0"
    },
    model: {
      value: _vm.sampling.monto,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "monto", $$v);
      },
      expression: "sampling.monto"
    }
  }) : _c('b-form-input', {
    attrs: {
      "id": "vi-monto",
      "type": "number",
      "step": "0.05",
      "placeholder": "No aplica",
      "min": "0",
      "disabled": ""
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Cantidad a entregar",
      "label-for": "vi-cantidad"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "HashIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-cantidad",
      "type": "number",
      "step": "1",
      "placeholder": "Cantidad a entregar",
      "min": "0"
    },
    model: {
      value: _vm.sampling.cantidad,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "cantidad", $$v);
      },
      expression: "sampling.cantidad"
    }
  })], 1)], 1)], 1), _vm.sampling.tipo === 'CATEGORIA' ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Categorias",
      "label-for": "vi-categorias"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "nombre",
      "reduce": function reduce(categoria) {
        return categoria.id;
      },
      "options": _vm.categorias,
      "multiple": ""
    },
    model: {
      value: _vm.sampling.categorias,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "categorias", $$v);
      },
      expression: "sampling.categorias"
    }
  })], 1)], 1) : _vm._e(), _vm.sampling.tipo === 'PRODUCTO' ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Productos",
      "label-for": "vi-productos"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-productos",
      "label": "nombre",
      "multiple": "",
      "reduce": function reduce(producto) {
        return producto.id;
      },
      "options": _vm.productos,
      "loading": _vm.loadingProductos,
      "filterable": true
    },
    on: {
      "search": _vm.searchProductData
    },
    model: {
      value: _vm.sampling.productos,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "productos", $$v);
      },
      expression: "sampling.productos"
    }
  })], 1)], 1) : _vm._e(), _vm.sampling.tipo === 'CLIENTE' ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo de Cliente",
      "label-for": "vi-cliente"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-cliente",
      "label": "text",
      "reduce": function reduce(tipoCliente) {
        return tipoCliente.value;
      },
      "options": _vm.tiposCliente
    },
    model: {
      value: _vm.sampling.cliente,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "cliente", $$v);
      },
      expression: "sampling.cliente"
    }
  })], 1)], 1) : _vm._e(), _vm.sampling.tipo === 'MONTO' || _vm.sampling.tipo === 'PRODUCTO' || _vm.sampling.tipo === 'PROMOCODE' ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Promocode",
      "label-for": "vi-promocode"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "nombre",
      "options": _vm.promocodes,
      "loading": _vm.loadingPromoCode,
      "filterable": true,
      "reduce": function reduce(promocode) {
        return promocode.id;
      }
    },
    model: {
      value: _vm.sampling.promocode,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "promocode", $$v);
      },
      expression: "sampling.promocode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Descripcion corta",
      "label-for": "vi-promocode-d1"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-promocode-d1",
      "placeholder": "Descripcion corta"
    },
    model: {
      value: _vm.sampling.pcDescripcionCorta,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "pcDescripcionCorta", $$v);
      },
      expression: "sampling.pcDescripcionCorta"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Descripcion Larga",
      "label-for": "vi-promocode-d2"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-promocode-d2",
      "placeholder": "Descripcion larga"
    },
    model: {
      value: _vm.sampling.pcDescripcionLarga,
      callback: function callback($$v) {
        _vm.$set(_vm.sampling, "pcDescripcionLarga", $$v);
      },
      expression: "sampling.pcDescripcionLarga"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.saveData
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }