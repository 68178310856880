<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{
          sampling.id === 0 ? "Crear Sampling" : "Modificar Sampling"
        }}</b-card-title
      >
      <div>
        <b-form-group label="Activo" label-for="vi-activo">
          <b-form-checkbox
            id="vi-activo"
            v-model="sampling.activo"
            class="custom-control-primary"
            name="check-button"
            switch
          />
        </b-form-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <b-form-radio-group
              id="btn-tipo"
              v-model="sampling.tipo"
              button-variant="outline-primary"
              :options="tipos"
              @change="onChangeType"
              buttons
              size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="7">
          <b-form @submit.prevent>
            <b-form-group label="Nombre" label-for="vi-nombre">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="GiftIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-nombre"
                  placeholder="Nombre del item"
                  v-model="sampling.nombre"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group label="Descripcion" label-for="vi-descripcion">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="BookIcon" />
                </b-input-group-prepend>
                <b-form-textarea
                  id="vi-descripcion"
                  v-model="sampling.descripcion"
                  placeholder="Descripcion"
                  rows="4"
                  no-resize
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Producto a entregar"
              label-for="vi-producto-sampling"
            >
              <v-select
                label="nombre"
                v-model="sampling.productoSampling"
                :reduce="(producto) => producto.id"
                :loading="loadingProductosSampling"
                :options="productosSampling"
                :clearable="false"
                :filterable="true"
              />
            </b-form-group>
          </b-form>
        </b-col>

        <b-col cols="12" md="5">
          <b-form-group label="Fecha Inicio" label-for="vi-fecha-ini">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr
                id="vi-fecha-ini"
                v-model="sampling.fechaInicio"
                class="form-control"
                :config="{
                  enableTime: true,
                  altInput: true,
                  altFormat: 'd/m/Y H:i',
                  dateFormat: 'Y-m-dTH:i:ss',
                }"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group label="Fecha Fin" label-for="vi-fecha-fin">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr
                id="vi-fecha-fin"
                class="form-control"
                v-model="sampling.fechaFin"
                :min-date="sampling.fechaInicio"
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y H:i',
                  dateFormat: 'Y-m-dTH:i:ss',
                  enableTime: true,
                }"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group label="Monto mínimo de compra" label-for="vi-monto">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="DollarSignIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-if="
                  sampling.tipo !== 'CLIENTE' && sampling.tipo !== 'PROMOCODE'
                "
                id="vi-monto"
                type="number"
                step="0.05"
                placeholder="Monto mínimo"
                min="0"
                v-model="sampling.monto"
              />
              <b-form-input
                v-else
                id="vi-monto"
                type="number"
                step="0.05"
                placeholder="No aplica"
                min="0"
                disabled
              />
            </b-input-group>
          </b-form-group>

          <b-form-group label="Cantidad a entregar" label-for="vi-cantidad">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="HashIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-cantidad"
                type="number"
                step="1"
                placeholder="Cantidad a entregar"
                min="0"
                v-model="sampling.cantidad"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="sampling.tipo === 'CATEGORIA'">
          <b-form-group label="Categorias" label-for="vi-categorias">
            <v-select
              v-model="sampling.categorias"
              label="nombre"
              :reduce="(categoria) => categoria.id"
              :options="categorias"
              multiple
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="sampling.tipo === 'PRODUCTO'">
          <b-form-group label="Productos" label-for="vi-productos">
            <v-select
              id="vi-productos"
              label="nombre"
              multiple
              @search="searchProductData"
              v-model="sampling.productos"
              :reduce="(producto) => producto.id"
              :options="productos"
              :loading="loadingProductos"
              :filterable="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="sampling.tipo === 'CLIENTE'">
          <b-form-group label="Tipo de Cliente" label-for="vi-cliente">
            <v-select
              id="vi-cliente"
              v-model="sampling.cliente"
              label="text"
              :reduce="(tipoCliente) => tipoCliente.value"
              :options="tiposCliente"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          v-if="
            sampling.tipo === 'MONTO' ||
            sampling.tipo === 'PRODUCTO' ||
            sampling.tipo === 'PROMOCODE'
          "
        >
          <b-row>
            <b-col cols="12" md="7">
              <b-form-group label="Promocode" label-for="vi-promocode">
                <v-select
                  v-model="sampling.promocode"
                  label="nombre"
                  :options="promocodes"
                  :loading="loadingPromoCode"
                  :filterable="true"
                  :reduce="(promocode) => promocode.id"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="5">
              <b-form-group
                label="Descripcion corta"
                label-for="vi-promocode-d1"
              >
                <b-form-input
                  id="vi-promocode-d1"
                  placeholder="Descripcion corta"
                  v-model="sampling.pcDescripcionCorta"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Descripcion Larga"
                label-for="vi-promocode-d2"
              >
                <b-form-input
                  id="vi-promocode-d2"
                  placeholder="Descripcion larga"
                  v-model="sampling.pcDescripcionLarga"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" class="mt-2">
          <b-button
            block
            @click="saveData"
            variant="primary"
            class="mr-1"
            :disabled="loading"
          >
            <b-spinner v-if="loading" small label="Loading..." />
            <div v-else>Guardar</div>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>


<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BButton,
  BSpinner,
  BCardBody,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BCardHeader,
  BInputGroup,
  BButtonGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormRadioGroup,
  BFormSpinbutton,
  BInputGroupPrepend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BButton,
    vSelect,
    BSpinner,
    flatPickr,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButtonGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    BFormSpinbutton,
    BInputGroupPrepend,
  },
  props: {
    sampling: {
      type: Object,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      tipos: [
        { text: "Monto", value: "MONTO" },
        { text: "Producto", value: "PRODUCTO" },
        { text: "Categoria", value: "CATEGORIA" },
        { text: "Cliente", value: "CLIENTE" },
        { text: "Promocode", value: "PROMOCODE" },
      ],
      loading: false,
      productos: [],
      promocodes: [],
      categorias: [],
      tiposCliente: [
        { text: "Primera vez", value: "1ERCOMPRA" },
        { text: "2da Compra", value: "2DACOMPRA" },
        { text: "3ra Compra", value: "3ERCOMPRA" },
        { text: "VIP", value: "CVIP" },
        { text: "Recuperado", value: "CRECUPERADO" },
      ],
      loadingProductos: false,
      loadingPromoCode: false,
      productosSampling: [],
      loadingCategorias: false,
      loadingProductosSampling: false,
    };
  },
  mounted() {
    this.fetchProductosSampling();
    this.onChangeType();
    if (this.sampling.tipo === "PRODUCTO")
      this.searchProductData("", this.sampling.productos);
  },
  methods: {
    onChangeType() {
      switch (this.sampling.tipo) {
        case "CATEGORIA":
          if (this.categorias.length === 0) this.fetchCategorias();
          break;
        case "MONTO":
        case "PRODUCTO":
        case "PROMOCODE":
          if (this.promocodes.length === 0) this.fetchPromoCodes();
          break;
        default:
          break;
      }
    },
    fetchProductosSampling() {
      this.loadingProductosSampling = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetSamplingProducts",
          body: JSON.stringify({
            empresa: 2,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loadingProductosSampling = false;
          if (res.data.bOk) {
            this.productosSampling = res.data.productos;
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.loadingProductosSampling = false;
          this.error = e.message;
        });
    },
    fetchPromoCodes() {
      this.loadingPromoCode = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetPromoCodes",
          body: JSON.stringify({
            empresa: 2,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loadingPromoCode = false;
          if (res.data.bOk) {
            this.promocodes = res.data.promocodes;
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.loadingPromoCode = false;
          this.error = e.message;
        });
    },
    searchProductData(query, ids) {
      if (query.length > 3 || typeof ids === "object") {
        this.loadingProductos = true;
        clearTimeout(this.seachTimeout);
        this.seachTimeout = setTimeout(() => {
          this.$http
            .post(this.$store.state.app.middlewareURL, {
              path: "/Administracion/SearchProduct",
              body: JSON.stringify({
                ids: ids !== null && typeof ids === "object" ? ids : [],
                query: query,
                empresa: 2,
                comercio: "MARKET",
                tokenSesion: getAuthToken(),
              }),
            })
            .then((res) => {
              if (res.data.bOk) {
                if (this.sampling.productos.length > 0) {
                  const aux = [];
                  this.sampling.productos.forEach((sp) => {
                    if (!res.data.productos.some((p) => p.id === sp)) {
                      const prd = this.productos.find((p) => p.id === sp);
                      if (prd) aux.push(prd);
                    }
                  });
                  res.data.productos = [...res.data.productos, ...aux];
                }
                this.productos = res.data.productos;
              } else {
                this.error = res.data.mensaje;
              }
              this.loadingProductos = false;
            })
            .catch((e) => {
              this.loadingProductos = false;
              this.error = e.message;
            });
        }, 1500);
      }
    },
    fetchCategorias() {
      this.loadingCategorias = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetCategories",
          body: JSON.stringify({
            empresa: 2,
            comercio: "MARKET",
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.categorias = res.data.categorias;
          } else {
            this.error = res.data.mensaje;
          }
          this.loadingCategorias = false;
        })
        .catch((e) => {
          this.loadingCategorias = false;
          this.error = e.message;
        });
    },
    saveData(e) {
      e.preventDefault();
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/Sampling",
          body: JSON.stringify({
            empresa: 2,
            sampling: this.sampling,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.refreshData().then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Exito",
                  icon: "CheckIcon",
                  text:
                    this.sampling.id === 0
                      ? "Se creo el Sampling correctamente"
                      : "Se actualizo correctamente el Sampling.",
                  variant: "success",
                },
              });
            });
            if (this.sampling.id === 0) {
              this.sampling.id = res.data.iDtemp;
            }
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text:
                this.sampling.id === 0
                  ? `Ocurrio un error al Crear el Sampling (${e.message}).`
                  : `Ocurrio un error al actualizar el Sampling (${e.message}).`,
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.iconEmpty {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

